import { useEffect, useState } from "react";
import { Button, Pagination, Table } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { supabase } from "../App";
import SortingMenu, { isEmpty } from "../Elements/SortingMenu";

export const dataStruct = {
    "check_num": ["Check Number", 2, (v) => {
        if (v === null || v === undefined) {
            return "-";
        } else {
            return v;
        }
    }],
    "type": ["Transaction Type", 2, (v) => {
        return v;
    }],
    "amount": ["Amount", 2, (v) => {
        return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(v);
    }],
    "date": ["Date", 2, (v) => {
        const temp = v.split("-");
        return temp[1] + "/" + temp[2] + "/" + temp[0];
    }],
    "name": ["Name", 2, (v) => {
        return v;
    }],
    "reason": ["Reason", 2, (v) => {
        return v;
    }],
    "cleared": ["Is Cleared?", 2, (v) => {
        if (v === true) {
            return "Yes";
        } else {
            return "No";
        }
    }]
}

const tableHeaders = <>
    {Object.values(dataStruct).map((e) => {
        return <th>{e[0]}</th>
    })}
    <th>Delete?</th>
</>

function Search({ limit }) {
    const [params] = useSearchParams();

    const tempPage = params.get("page");
    const sort = params.get("sort");
    const filter = params.get("filter");

    const [vars, setVars] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState((() => {
        if (isEmpty(tempPage)) {
            return (1);
        } else {
            return (tempPage);
        }
    })());

    window.history.pushState({}, "", "/search?page=" + page);

    useEffect(() => {
        window.history.pushState({}, "", "/search?page=" + page)
    }, [sort])

    const tableBody = <>
        {vars.map((e) => {
            const id = e.id;
            return <tr>
                {Object.keys(dataStruct).map((j) => {
                    if (j in e) {
                        return <td onClick={() => {
                            window.location.replace("/add?id=" + e.id)
                        }}>{dataStruct[j][2](e[j])}</td>;
                    } else {
                        return <th onClick={() => {
                            window.location.replace("/add?id=" + e.id)
                        }}>-</th>
                    }
                })}
                <td><Button onClick={(e) => {
                    const bool = window.confirm("Are you sure you want to delete this transaction?");
                    if (bool) {
                        supabase.from("data").delete().eq("id", id).then((e) => { window.location.reload() });
                    }
                }}>Delete?</Button></td>
            </tr>
        })}
    </>;

    return <>
        <SortingMenu maxTransactionsPerPage={25} data={setVars} currentPage={page} pageCountSetter={setPageCount} oldSort={sort} oldFilter={filter}/>
        <Table responsive="sm" striped hover bordered>
            <thead>
                <tr>
                    {tableHeaders}
                </tr>
            </thead>
            <tbody>
                {tableBody}
            </tbody>
        </Table>
        <Pagination>
            <Pagination.Prev onClick={() => {
                const n = new Number(page) - 1;
                if (!(1 > n)) {
                    window.location.replace("/search?page=" + n)
                }
            }}></Pagination.Prev>
            <Pagination.Item>{page}</Pagination.Item>
            <Pagination.Next onClick={() => {
                const n = new Number(page) + 1;
                if (!(n > pageCount)) {
                    window.location.replace("/search?page=" + n);
                }
            }}></Pagination.Next>
        </Pagination>
    </>
}

export default Search;