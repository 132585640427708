import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { supabase, userID } from "../App";
import DefaultFormSpacer from "../Elements/DefaultFormSpacer";
import { isEmpty } from "../Elements/SortingMenu";
import { dataStruct } from "./Search";

export function InlineDivContainer({ element }) {
    return <div style={{ display: "flex" }}>
        {element}
    </div>
}

export const typeWords = ["Deposit", "Withdrawal"];

function getPlaceholder(type, val) {
    if (val === null || val === undefined) return "";
    if (type === "date") {
        return dataStruct["date"][2](val);
    } else {
        return (val);
    }
}

export function GenericFormComponent({ id, label, type, change, val }) {
    const [tempval, setTempval] = useState();
    const [itype, setType] = useState("text");

    const iChange = (e) => {
        if (type === "number") {
            const temp = new String(e);
            if (temp.includes(".") && temp.split(".")[1].length > 2) {
                setTempval(Number.parseFloat(e).toFixed(2));
                change(Number.parseFloat(e).toFixed(2));
            } else {
                setTempval(e);
                change(Number.parseFloat(e));
            }
        } else {
            setTempval(e);
            change(e);
        }
    }

    return <>
        <Form.Group controlId={id} style={{ margin: "5px" }}>
            <InlineDivContainer element={
                <>
                    <Form.Label>{label}</Form.Label>
                    <DefaultFormSpacer></DefaultFormSpacer>
                    <Form.Control onFocus={() => { setType(type) }} onBlur={() => { setType("text") }} step=".01" placeholder={getPlaceholder(type, val)} value={tempval} onChange={(e) => { iChange(e.target.value) }} name={id} type={itype}></Form.Control>
                </>
            } />
        </Form.Group>
    </>;
}

function DepositWithdrawalSelect({ change, val }) {
    const [tempval, setTempval] = useState();

    useEffect(() => {
        if (val) {
            setTempval(val);
        } else {
            setTempval(typeWords[0]);
        }
    }, [val])

    return <>
        <InlineDivContainer element={
            <>
                <Form.Label>Transaction Type</Form.Label>
                <Form.Select value={tempval} onChange={(e) => { setTempval(e.target.value); change(e.target.value) }}>
                    {
                        typeWords.map((e) => {
                            return <option value={e}>{e}</option>
                        })
                    }
                </Form.Select>
            </>
        } />
    </>;
}

function getData(userid, id, state) {
    if (!(id === null || id === undefined))
        return supabase.from("data").select("user_id", userid).eq("id", id).select().single().then((e) => {
            state(Object.assign(emptyDataObject, e.data));
        });
}

async function setData(id, data) {
    if (!(id === null || id === undefined)) // id = row id
        await supabase.from("data").upsert(data).eq(id);
    else
        await supabase.from("data").insert(data);
}

const emptyDataObject = {
    check_num: null,
    type: "Deposit",
    amount: 0,
    date: new Intl.DateTimeFormat("en-CA", {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone}).format(new Date()).substring(0, 10),
    name: "",
    reason: "",
    cleared: false
}

function Add() {

    const [params] = useSearchParams();

    const thisID = params.get("id");

    const [vars, setVars] = useState({});

    useEffect(() => {
        if (thisID !== null && thisID !== undefined) {
            userID().then((e) => {
                getData(e, thisID, setVars);
            });

        } else {
            setVars(emptyDataObject);
        }
    }, []);

    const setField = (tag) => {
        return (e) => {
            var temp = vars;
            temp[tag] = e;
            setVars(temp);
        }
    }

    const getField = (tag) => {
        return vars[tag];
    };

    const submit = async (back) => {
        await setData(thisID, vars);
        if (isEmpty(back)) {
            window.location.replace("/search");
        } else {
            window.location.reload();
        }
    }

    const checkChange = e => {
        setField("cleared")((isEmpty(getField("cleared")) ? true : !getField("cleared")));
        if (!isEmpty(thisID)) submit(true);
    }

    const checkVal = () => {
        if (isEmpty(thisID)) return null;
        return isEmpty(getField("cleared")) ? false : getField("cleared");
    }
    
    return <>
        <Row>
            <Col sm={3}></Col>
            <Col sm={6}>
                <Card fluid style={{ padding: "5px" }}>
                    <Form>
                        <GenericFormComponent id="check_num" label="Check Number" type="number" change={setField("check_num")} val={getField("check_num")} ></GenericFormComponent>
                        <DepositWithdrawalSelect change={setField("type")} val={getField("type")} />
                        <GenericFormComponent id="amount" label="Amount" type="number" change={setField("amount")} val={getField("amount")}></GenericFormComponent>
                        <GenericFormComponent id="date" label="Date" type="date" change={setField("date")} val={getField("date")}></GenericFormComponent>
                        <GenericFormComponent id="name" label="Name" type="text" change={setField("name")} val={getField("name")}></GenericFormComponent>
                        <GenericFormComponent id="reason" label="Reason" type="text" change={setField("reason")} val={getField("reason")}></GenericFormComponent>
                        <InlineDivContainer element={<>
                            <Form.Label>Is Cleared?</Form.Label>
                            <DefaultFormSpacer></DefaultFormSpacer>
                            <input type="checkbox" id="cleared" onClick={checkChange} 
                                checked={checkVal()}></input>
                        </>} />
                        <Button onClick={(e) => {submit()}}>Submit</Button>
                    </Form>
                </Card>
            </Col>
            <Col sm={3}></Col>
        </Row>
    </>;
}
export default Add;