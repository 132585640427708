import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import { supabase, userID } from "../App";
import { GenericFormComponent, InlineDivContainer } from "../Pages/Add";
import DefaultFormSpacer from "./DefaultFormSpacer";

export const isEmpty = (v) => {
    if (v === null) {
        return true;
    } else if (v === undefined) {
        return true;
    } else if (v === "") {
        return true;
    }
    return false;
}

async function sortingFunctionFactory(sorting, filtering, setCount, currentPage, typefilter, maxTransactionsPerPage) {
    const id = await userID();
    var parent = supabase.from("data").select("user_id", id);
    if (!(isEmpty(filtering))) {
        var data = filtering?.filter((a) => !isEmpty(a));
        if (!(isEmpty(typefilter))) {
            data.push(typefilter);
        }
        data = data.join();
        if (!(isEmpty(data))) {
            const finalData = "and(" + data + ")";
            parent = parent.or(finalData);
        }
    }
    parent.select("count").then((e) => {
        if (e.data[0].count) setCount(e.data[0].count);
        else setCount(undefined);
    })
    if (!(isEmpty(sorting))) {
        parent = parent.select().order(sorting, { ascending: true });
    }
    if (isEmpty(currentPage)) return parent.select();
    const lowerRange = (currentPage - 1) * maxTransactionsPerPage;
    const upperRange = lowerRange + maxTransactionsPerPage - 1;
    return parent.select().range(lowerRange, upperRange).select();
}

function GenericSortingOption({ type, label, change, one, two, isRange }) {
    const [on, setOn] = useState((isRange && (one && two)) || (!isRange && (one || two)));
    const [valOne, setValOne] = useState((one ? one : null));
    const [valTwo, setValTwo] = useState((two ? two : null));

    useEffect(() => {
        if (on) {
            changeFunc();
        } else {
            change(null);
        }
    }, [on, valOne, valTwo]);

    const changeFunc = (v) => {
        if (isRange === true) {
            change(valOne, valTwo);
        } else {
            change(valOne);
        }
    }

    return <>
        <InlineDivContainer element={<>
            <Form.Check onChange={(e) => { setOn(!on); }} checked={on}></Form.Check>
            <DefaultFormSpacer></DefaultFormSpacer>
            <GenericFormComponent type={type} label={label} change={setValOne}
                val={valOne}></GenericFormComponent>
            {isRange ? <GenericFormComponent type={type} label={" to "} change={setValTwo} val={valTwo}></GenericFormComponent> : null}
        </>}>

        </InlineDivContainer></>;
}

export const filterList = [
    {
        id: 1,
        name: "Name",
        isRange: false,
        type: "text",
        operator: (a) => {
            if (isEmpty(a)) return "";
            return "name.ilike.\"" + a + "\"";
        }
    },
    {
        id: 2,
        name: "Date",
        isRange: true,
        type: "date",
        operator: (a, b) => {
            if (isEmpty(a) || isEmpty(b)) return null;
            return "and(date.gte." + a.toString() + ", date.lte." + b.toString() + ")";
        }
    }
]

const typeFilterList = [
    {
        name: "All",
        operator: ""
    },
    {
        name: "Deposit",
        operator: "type.ilike.deposit"
    },
    {
        name: "Withdrawal",
        operator: "type.ilike.\"withdrawal\""
    }

]

const sortList = [
    {
        id: 1,
        name: "Name",
        operator: "name"
    },
    {
        id: 2,
        name: "Date",
        operator: "date"
    }
]

function SortingMenu({ data, pageCountSetter, currentPage, maxTransactionsPerPage }) {
    const [sort, setSort] = useState(!!window.sessionStorage.getItem("sort") ? window.sessionStorage.getItem("sort") : "");
    const [filter, setFilter] = useState(!!window.sessionStorage.getItem("filter") ? new Map(JSON.parse(window.sessionStorage.getItem("filter"))) : new Map());
    const [typeFilter, setTypeFilter] = useState(!!window.sessionStorage.getItem("typeFilter") ? window.sessionStorage.getItem("typeFilter") : "");

    const processedFilters = () => {
        var finalFilters = Array.from(filter.values()).filter((e) => !isEmpty(e)).flatMap((e) => (e.operator));
        if (finalFilters.length === 0) {
            finalFilters = null;
        }
        return finalFilters;
    }

    const currentPageFunc = (e) => {
        if (e % maxTransactionsPerPage !== 0) {
            pageCountSetter(((e / maxTransactionsPerPage) + 1).toFixed(0));
        } else {
            pageCountSetter((e / maxTransactionsPerPage).toFixed(0));
        }
    };

    useEffect(() => {
        const dataRaw = sortingFunctionFactory((sort === "" ? null : sort), (processedFilters() === null ? null : processedFilters()), currentPageFunc, currentPage, typeFilter, maxTransactionsPerPage);
        dataRaw.then((result) => {
            let temp = [];
            Object.values(result.data).forEach((v) => {
                temp.push(v);
            })
            data(temp);
        })
    }, [])

    const onSubmit = () => {
        window.sessionStorage.setItem("sort", sort);
        window.sessionStorage.setItem("filter", JSON.stringify(Array.from(filter.entries())))
        window.sessionStorage.setItem("typeFilter", typeFilter);
        sortingFunctionFactory(sort, processedFilters(), currentPageFunc, 1, typeFilter, maxTransactionsPerPage).then((result) => {
            let temp = [];
            Object.values(result.data).forEach((v) => {
                temp.push(v);
            })
            data(temp);
        })
    }

    const getFilterChangeFunction = (id, operator, isRange) => {
        const n = id;
        if (isRange) {
            return (a, b) => {
                if (isEmpty(a) || isEmpty(b)) {
                    setFilter(filter.set(n, null));
                }
                setFilter(filter.set(n, {
                    values: [a, b],
                    operator: operator(a, b)
                }));
            }
        } else {
            return (a) => {
                if (isEmpty(a)) {
                    setFilter(filter.set(n, null));
                }
                setFilter(filter.set(n, {
                    values: [a, null],
                    operator: operator(a)
                }));
            }
        }
    }

    return <>
        <Card>
            <Table>
                <Row>
                    <Col>
                        Sort:
                        {sortList.map((e) => {
                            return <>
                                <InlineDivContainer element={<>
                                    <Form.Check checked={sort.includes(e.operator)} onClick={() => { setSort(e.operator) }}></Form.Check>
                                    <DefaultFormSpacer></DefaultFormSpacer>
                                    <Form.Label>{e.name}</Form.Label>
                                </>}></InlineDivContainer>
                            </>
                        })}
                        Filter:
                        {filterList.map((e) => {
                            const tempone = (filter.get(e.id) === undefined ? null : filter.get(e.id).values[0]);
                            const temptwo = (filter.get(e.id) === undefined ? null : filter.get(e.id).values[1]);
                            return <GenericSortingOption label={e.name} type={e.type} change={getFilterChangeFunction(e.id, e.operator, e.isRange)} one={tempone} two={temptwo} isRange={e.isRange}></GenericSortingOption>
                        })}
                        <InlineDivContainer element={<>
                            Type:
                            <DefaultFormSpacer></DefaultFormSpacer>
                            <Form.Select value={(isEmpty(typeFilter) ? typeFilterList[0].operator : typeFilter)} onChange={(e) => {
                                setTypeFilter(e.target.value);
                            }}>
                                {typeFilterList.map((e) => {
                                    return <option value={e.operator}>{e.name}</option>
                                })}
                            </Form.Select>
                        </>}/>
                    </Col>
                </Row>
                <Button onClick={() => { onSubmit() }}>Submit</Button>
            </Table>
        </Card>
    </>;
}

export default SortingMenu;