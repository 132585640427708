import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { supabase, userID } from "../App";
import DefaultFormSpacer from "../Elements/DefaultFormSpacer";
import { filterList } from "../Elements/SortingMenu";
import { InlineDivContainer } from "./Add";
import { totalTypes } from "./Dashboard";
import { dataStruct } from "./Search";

function Export() {
    const [startDate, setStartDate] = useState(new Intl.DateTimeFormat("en-CA", { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }).format(new Date(new Date().getTime() - (90 * 24 * 60 * 60 * 1000))).substring(0, 10));
    const [endDate, setEndDate] = useState(new Intl.DateTimeFormat("en-CA", { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }).format(new Date()).substring(0, 10));
    const beginningOfYear = (() => {
        return new Intl.DateTimeFormat("en-CA", { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }).format(new Date()).substring(0, 4) + "-01-01";
    })();

    const downloadPDF = async (run) => {
        var totalData = [];

        var temp = ["Period between " + dataStruct["date"][2](startDate) + " to " + dataStruct["date"][2](endDate)];
        for (const a of totalTypes) {
            temp.push(new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format((await supabase.rpc("user_sum", {
                typevar: a,
                startdate: startDate,
                enddate: endDate
            })).data))
        }

        totalData.push(temp);

        temp = ["YTD"];
        for (const a of totalTypes) {
            temp.push(new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format((await supabase.rpc("user_sum", {
                typevar: a,
                startdate: beginningOfYear,
                enddate: new Intl.DateTimeFormat("en-CA", { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }).format(new Date())
            })).data))
        }

        totalData.push(temp);

        console.log(totalData);

        supabase.from("data").select("user_id", userID()).or(filterList.filter((e) => (e.name === "Date"))[0].operator(startDate, endDate)).select().then((e) => {
            var temp = [];
            Object.values(e.data).sort((a, b) => (a["date"].localeCompare(b["date"]))).forEach((v) => {
                temp.push(v);
            })

            console.log(temp);

            var rawData = [];

            temp.forEach((e) => {

                var temp = [];
                Object.keys(dataStruct).forEach((j) => {
                    if (j in e) {
                        temp.push(dataStruct[j][2](e[j]));
                    } else {
                        temp.push("-")
                    }
                })
                rawData.push(temp);

            })

            console.log(rawData)

            const pdf = new jsPDF({
                format: "letter",
                unit: "in",
                orientation: "p"
            });

            autoTable(pdf, {
                head: [["MBYC Trust Account Transactions"]],
                body: [["Exported on " + new Intl.DateTimeFormat("en-US", { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }).format(new Date())],
                ["Period between " + dataStruct["date"][2](startDate) + " to " + dataStruct["date"][2](endDate)]
                ]
            })

            autoTable(pdf, {
                head: [["Totals:", ...Array(3)], [""].concat(totalTypes)],
                body: totalData,
            })

            autoTable(pdf, {
                head: [["Transactions:", ...Array(Object.values(dataStruct).length)], Object.values(dataStruct).flatMap((e) => e[0])],
                body: rawData,
                showHead: "firstPage"
            })

            if (run) {
                pdf.save(new Intl.DateTimeFormat("en-US", { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }).format(new Date()) + " ledger.pdf")
            }
        })
    }

    return <>
        <div>
            <p style={{ textAlign: "left" }}>Enter in the desired date range to download:</p>
            <div style={{ height: "5px" }}></div>
            <InlineDivContainer element={
                <>
                    <input type="date" value={startDate} onChange={(e) => { setStartDate(e.target.value) }}></input>
                    <DefaultFormSpacer></DefaultFormSpacer>
                    <p>to</p>
                    <DefaultFormSpacer></DefaultFormSpacer>
                    <input type="date" value={endDate} onChange={(e) => { setEndDate(e.target.value) }}></input>
                </>
            } />
            <Button onClick={() => {
                downloadPDF(true);
            }}>Download PDF</Button>
        </div>
    </>
}

export default Export;