import { useCallback, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import MyNav from './Elements/MyNav';
import Add from './Pages/Add';
import Dashboard from './Pages/Dashboard';
import Login, { centerStyle } from './Pages/Login';
import Search from './Pages/Search';

import { createClient } from '@supabase/supabase-js';
import Export from './Pages/Export';

export const supabaseUrl = 'https://bznewcoymzpynzchetdu.supabase.co'
export const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJ6bmV3Y295bXpweW56Y2hldGR1Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDc5NTc0MzMsImV4cCI6MjAyMzUzMzQzM30.AsEo9fX8xck30aMhxW1qLH6PSoTqNBlVOQGgYyk0pUQ"
export const supabase = createClient(supabaseUrl, supabaseKey)
export const userID = async () => {
  return (await supabase.auth.getSession()).data.session.user.id;
}

function App() {
  const divstyle = {
    padding: "10px",
  }

  const [loading, isLoading] = useState(true);
  const [isSignedIn, setSignedIn] = useState(false);

  useEffect(() => {
    supabase.auth.getSession().then(({data: {session}}) => {
      isLoading(false);
      if (!session) setSignedIn(false);
      else setSignedIn(true);
    })
  }, [])

  const [width, setWidth] = useState(0);
  const control = useCallback(n => {
    if (n != null) setWidth(n.getBoundingClientRect().width);
  })

  return (
    <div className="App">
      {loading ? <Spinner style={centerStyle}></Spinner> : <>
        {isSignedIn ? <MyNav></MyNav> : null}
        <BrowserRouter>
          <div style={divstyle}>
            <Routes>
              <Route path='/login' element={<Login />}></Route>
              <Route path='/' element={<Dashboard />}></Route>
              <Route path='/search' element={<Search />}></Route>
              <Route path='/add' element={<Add />}></Route>
              <Route path='/export' element={<Export />}></Route>
            </Routes>
            {isSignedIn ? null : <Navigate to="/login" />}
          </div>
        </BrowserRouter>
      </>}
    </div >
  );
}

export default App;
