import { useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { supabase } from "../App";
import { GenericFormComponent } from "./Add";

export const centerStyle = { padding: "5px", position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)" };

function Login() {
    const [user, setUser] = useState("");
    const [password, setPassword] = useState("");

    const login = async () => {
        await supabase.auth.signInWithPassword({
            email: user,
            password: password
        });
        window.location.replace("/");
    }

    return <>
            <>
                <div style={centerStyle}>
                    <Card>
                        <Form>
                            <GenericFormComponent type={"email"} label={"Email"} id={"user"} change={setUser}></GenericFormComponent>
                            <GenericFormComponent type={"password"} label={"Password"} id={"password"} change={setPassword}></GenericFormComponent>
                            <Button onClick={login}>Log In</Button>
                        </Form>
                    </Card>
                </div>
            </>

    </>
}

export default Login;