import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { supabase } from '../App';

function MyNav() {
    const logOut = () => {
        // https://stackoverflow.com/questions/7667958/clearing-localstorage-in-javascript
        supabase.auth.signOut();
        localStorage.clear();
        window.location.replace("/");
    };

    return <>
        <Navbar className='bg-body-secondary' expand="lg" sticky="top" color='gray'>
            <Container>
                <Navbar.Brand>Virtual Checkbook</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"></Navbar.Toggle>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav>
                        <Nav.Link href="/">Dashboard</Nav.Link>
                        <Nav.Link href="/search">Search</Nav.Link>
                        <Nav.Link href="/add">Add a Transaction</Nav.Link>
                        <Nav.Link href="/export">Export</Nav.Link>
                        <Button onClick={logOut}>Log Out</Button>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    </>;
}

export default MyNav;