import { useEffect, useState } from "react";
import { Card, Table } from "react-bootstrap";
import { supabase } from "../App";
import DefaultFormSpacer from "../Elements/DefaultFormSpacer";
import { InlineDivContainer } from "./Add";

export const totalTypes = ["Deposit", "Withdrawal", "Total"];

function Dashboard() {
    const [startDate, setStartDate] = useState(new Intl.DateTimeFormat("en-CA", {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone}).format(new Date(new Date().getTime() - (90 * 24 * 60 * 60 * 1000))).substring(0, 10));
    const [endDate, setEndDate] = useState(new Intl.DateTimeFormat("en-CA", {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone}).format(new Date()).substring(0, 10));
    const [totalValuesPeriod, setTotalValues] = useState(new Map());
    const [totalValuesYtd, setTotalValuesYtd] = useState(new Map());

    const usdMaker = (e) => {
        return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(e);
    }

    const beginningOfYear = (() => {
        return new Intl.DateTimeFormat("en-CA", {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone}).format(new Date()).substring(0, 4) + "-01-01";
    })();

    useEffect(() => {
        totalTypes.forEach((a) => {
            supabase.rpc("user_sum", {
                typevar: a,
                startdate: startDate,
                enddate: endDate
            }).then((e) => {
                setTotalValues(new Map(totalValuesPeriod.set(a, usdMaker(e.data))));
            })
        })
    }, [startDate, endDate])

    useState(() => {
        totalTypes.forEach((a) => {
            supabase.rpc("user_sum", {
                typevar: a,
                startdate: startDate,
                enddate: endDate
            }).then((e) => {
                setTotalValues(new Map(totalValuesPeriod.set(a, usdMaker(e.data))));
            })
        })
        totalTypes.forEach((a) => {
            supabase.rpc("user_sum", {
                typevar: a,
                startdate: beginningOfYear,
                enddate: endDate
            }).then((e) => {
                setTotalValuesYtd(new Map(totalValuesPeriod.set(a, usdMaker(e.data))));
            })
        })
    }, [])

    return <>
        <Card style={{ overflow: "auto" }}>
            <>
                <Card.Body>
                    <Table>
                        <tr>
                            <h4 style={{ whiteSpace: "nowrap", overflow: "visible ", width: "fit-content" }}>Total Over Days:  </h4>
                            <InlineDivContainer element={
                                <>
                                    <div style={{ display: "flex" }}>
                                        <DefaultFormSpacer></DefaultFormSpacer>
                                        <input type="date" value={startDate} onChange={(e) => { setStartDate(e.target.value) }}></input>
                                        <DefaultFormSpacer></DefaultFormSpacer>
                                        <h4> to </h4>
                                        <DefaultFormSpacer></DefaultFormSpacer>
                                        <input type="date" value={endDate} onChange={(e) => { setEndDate(e.target.value) }}></input>
                                    </div>
                                </>
                            }></InlineDivContainer>
                        </tr>
                        <tr>
                            {
                                totalTypes.map((e) => {
                                    return <>
                                        <th style={{ width: "calc(100% / " + totalTypes.length + ")" }}>
                                            <h4>{e}</h4>
                                            <h2>{totalValuesPeriod.get(e)}</h2>
                                        </th>
                                    </>
                                })
                            }
                        </tr>
                        <tr>
                            <h4 style={{ whiteSpace: "nowrap", overflow: "visible ", width: "fit-content" }}>Total Year to Date:  </h4>
                        </tr>
                        <tr>
                            {
                                totalTypes.map((e) => {
                                    return <>
                                        <th style={{ width: "calc(100% / " + totalTypes.length + ")" }}>
                                            <h4>{e}</h4>
                                            <h2>{totalValuesYtd.get(e)}</h2>
                                        </th>
                                    </>
                                })
                            }
                        </tr>
                    </Table>
                </Card.Body>
            </>
        </Card>
    </>;
}

export default Dashboard;